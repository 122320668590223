// about style hear

.rts-read-more-2{
    display: flex;
    align-items: center;
    color: #fff;
    transition: .3s;
    i{
        margin-left: 10px;
        font-size: 24px;
        color: #fff;
        height: 27px;
        width: 27px;
        border-radius: 5px;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
}


.about-one-left-wrapper{
    position: relative;
    .left-detail-thumb{
        position: absolute;
        bottom: 0;
        left: 0;
        background: #0C0A0A;
        padding: 40px 50px;
        .title{
            color: #fff;
        }
    }
    img{
        width: 100%;
    }
}

.bg-white{
    background: #fff;
}

.right--div {
    position: relative;
    min-width: 123%;
    left: -23%;
    right: 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1919px) {
        left: 0;
        min-width: 100%;
    }
}

.about-one-right-wrapper{
    background: #FFFFFF;
    height: 100%;
    padding: 0 80px;
    @media screen and (max-width: 1919px) {
        padding: 0 40px;
    }
    @media #{$smlg-device} {
        background: transparent;
        margin-top: 30px;
        padding: 0;
    }
}


.header--sticky {
    transition: 0.3s;
    top: 0;
    z-index: 5;
    position: relative;
}

.header--sticky.sticky {
    position: fixed !important;
    top: 0;
    display: block;
    backdrop-filter: blur(9px);
    width: 100%;
    box-shadow: 0px 7px 18px #1810100d;
    background: #fff;
    z-index: 999;
}


.rts-tab-one-start{
    ul.nav{
        background: #F2F3F5;
        padding: 20px 30px;
        border: none;
        margin-top: 40px;
        margin-bottom: 30px;
        @media screen and (max-width: 1919px) {
            padding: 15px;
        }
        @media #{$laptop-device} {
            margin-top: 10px;
            margin-bottom: 14px;
        }
        @media #{$large-mobile} {
            margin: 10px 0;
            background: transparent !important;
        }
        @media #{$small-mobile} {
            padding: 0;
        }
        li{
            margin: 0;
            padding: 0;
            margin-right: 50px;
            button{
                color: #0C0A0A;
                font-family: var(--font-primary);
                padding: 0;
                margin: 0;
                background: transparent;
                border: none;
                font-weight: 600;
                font-size: 18px;
                position: relative;
                transition: .3;
                &::after{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    height: 2px;
                    width: 0;
                    content: '';
                    background: #0C0A0A;
                }
                &[aria-selected="true"]{
                    background: transparent;
                    border: none;
                    margin-left: 30px;
                    color: #0C0A0A;
                    font-weight: 600;
                    &::after{
                        width: 40px;
                        left: -33px;
                    }
                    @media #{$small-mobile} {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

.single-tab-content-one{
    p{
        &.disc{
            margin-bottom: 30px;
            @media screen and (max-width: 1919px) {
                font-size: 15px;
                margin-bottom: 10px;
            }
            @media #{$smlg-device} {
                width: 74%;
            }
            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }
    .jurney-from{
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 30px;
        @media screen and (max-width: 1919px) {
            gap: 20px;
        }
        @media #{$small-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .left-jr{
            .title{
                font-size: 60px;
                font-weight: 700;
                font-family: var(--font-primary);
                color:var(--color-primary);
            }
            span.sub{
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.08em;
                color: #161921;
                text-transform: uppercase;
            }
        }
        .right-jr{
            .single{
                margin-bottom: 7px;
                &.contact{
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    i{
                        border-radius: 50%;
                        background: var(--color-primary);
                        color: #fff;
                        font-size: 10px;
                        height: 23px;
                        width: 23px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                    }
                    a{
                        color: #0C0A0A;
                        transition: .3s;
                        font-weight: 400;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
                i{
                    color: var(--color-primary);
                    margin-right: 10px;
                }
                span{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 32px;
                    color: #0C0A0A;
                    @media screen and (max-width: 1919px) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}


// home three title area starat

.title-three-left{
    position: relative;
    .bg-title{
        font-size: 150px;
        position: absolute;
        left: 0;
        top: -13px;
        z-index: -1;
        text-transform: uppercase;
        font-family: var(--font-primary);
        font-weight: 700;
        background: linear-gradient(180deg, #0C0A0A 0%, rgba(12, 10, 10, 0) 100%);
        opacity: 0.06;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        height: 93px;
        display: flex;
        align-items: center;
        -webkit-text-stroke: 1px #000000;
        @media(max-width:576px){
            font-size: 100px;
            top: -25px;
        }
        @media(max-width:450px){
            font-size: 68px;
            top: -30px;
        }
    }
    span.pre-title{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        font-family: var(--font-primary);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #0C0A0A;
    }
    .title{
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        color: #0C0A0A;
        margin-top: 12px;
        @media #{$md-layout} {
            font-size: 36px;
            line-height: 50px;
        }
        @media #{$sm-layout} {
            font-size: 36px;
            line-height: 50px;
        }
        @media #{$small-mobile} {
            font-size: 24px;
            line-height: 41px;
        }
    }
}

.title-three-center{
    position: relative;
    text-align: center;
    .bg-title{
        font-size: 150px;
        position: absolute;
        left: 0;
        top: -51px;
        z-index: -1;
        text-transform: uppercase;
        font-family: var(--font-primary);
        font-weight: 700;
        background: linear-gradient(180deg, #0C0A0A 0%, rgba(12, 10, 10, 0) 100%);
        opacity: 0.06;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        height: 93px;
        display: flex;
        align-items: center;
        -webkit-text-stroke: 1px #000000;
        left: 50%;
        transform: translateX(-50%);
        min-width: max-content;
    }
    span.pre-title{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        font-family: var(--font-primary);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #0C0A0A;
    }
    .title{
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        color: #0C0A0A;
        margin-top: 12px;
    }
}


// about for home three
.about-image-thumbnail-3{
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 150px;
    @media(max-width:991px){
        margin-bottom: 60px;
    }
    @media(max-width:576px){
        margin-bottom: 100px;
    }
    .image-2{
        // margin-left: -40%;
        position: absolute;
        right: 0;
        top: -150px;
        animation: jump-2 8s linear infinite;
    }
}

.single-tab-content-three{
    margin-top: 20px;
    .disc{
        margin-bottom: 20px;
    }
    .list-wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .single{
            margin-right: 30px;
            ul{
                margin-left: 20px;
                li{
                    margin-bottom: 10px;
                    margin-top: 5px;
                }
            }
        }
    }
}

.rts-tab-three-start{
    margin-top: 45px;
    ul{
        padding: 0;
        margin: 0;
        border: none;
        li{
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 20px;
            @media(max-width:500px){
                margin-bottom: 20px;
            }
            button{
                background: transparent !important;
                font-weight: 500;
                font-size: 16px;
                line-height: 17px;
                color: #171717;
                border: 1px solid #F0F0F0 !important;
                padding: 12px 34px;
                &:hover{
                    background: #171717 !important;
                    color: #fff !important;
                }
                &[aria-selected="true"]{
                    background: #171717 !important;
                    color: #fff !important;
                }
            }
        }
    }
}


.thumbnail-area-about{
    position: relative;
    img.small{
        position: absolute;
        right: 0;
        top: 40px;
        animation: jump-2 5s  linear infinite;
        @media #{$smlg-device} {
            right: -44px;
        }
        @media #{$md-layout} {
            right: 50px;
        }
    }
}

.main-content-area-about-p{
    p.disc{
        margin-bottom: 40px;
        margin-top: 40px;
    }
    .service-wrapper{
        display: flex;
        align-items: center;
        gap: 130px;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }
        .single-service{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            i{
                margin-right: 8px;
                color: #0C0A0A;
                font-weight: 400;
            }
            p{
                margin-bottom: 0;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #0C0A0A;
                font-family: var(--font-primary);
            }
        }
    }
    .authore-artea-wrapper{
        display: flex;
        align-items: center;
        margin-top: 20px;
        gap: 40px;
        @media #{$large-mobile} {
            flex-direction: column;
            gap: 30px;
            align-items: flex-start;
        }
    }
    .main-authore-wrapper{
        display: flex;
        align-items: center;
        gap: 15px;
        .author{
            img{
                width: 100%;
            }
        }
        .info{
            .title{
                margin-bottom: 5px;
            }
        }
    }
}



.appoinment-m-thumb{
    position: relative;
    .inner-wrapper{
        position: absolute;
        bottom: 80px;
        padding: 35px 50px 35px 40px;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        margin-left: -100px;
        animation: jump-2 5s linear infinite;
        @media #{$small-mobile} {
            padding: 7px;
            padding: 7px;
            margin-left: 0;
        }
        h6{
            font-weight: 700;
            font-size: 60px;
            line-height: 60px;
            color: #FFF;
            margin-right: 20px;
            @media #{$small-mobile} {
                font-size: 40px;
            }
        }
        span{
            color: #fff;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-top: -10px;
        }
    }
}
.rts-make-an-appoinemtn-area{
    &.home-seven{
        .appoinment-m-thumb{
            .inner-wrapper{
                position: absolute;
                bottom: 40px;
                left: -160px;
                padding: 0;
                background: none;
                margin-left: 0;
                @media(max-width:768px){
                    bottom: 0;
                    left: 0;
                }
                @media(max-width:500px){
                    display: none;
                }
            }
        }
        .appoinment-inner-content-wrapper{
            span{
                color: var(--color-primary);
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .title{
                margin-top: 10px;
            }
            form{
                .input-wrapper{
                    .single-input{
                        width: 100%;
                        input{
                            height: 60px;
                            background: #FFFFFF;
                            display: block;
                            padding: 0 30px;
                            border: 1px solid transparent;
                            &:focus{
                                border: 1px solid var(--color-primary);
                            }
                        }
                    }
                }
                .input-half-wrapper{
                    .single-input{
                        width: 50%;
                        select{
                            margin-top: 0;
                        }
                    }
                }
                textarea{
                    background: #FFFFFF;
                    margin-bottom: 30px;
                    padding: 15px;
                    height: 150px;
                    &:focus{
                        border: 1px solid var(--color-primary);
                    }
                }
                .rts-btn.btn-primary{
                    display: flex;
                    align-items: center;
                    padding: 10px 15px 10px 25px;
                    i{
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        color: var(--color-primary);
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}


.thumbnail-area-about{
    position: relative;
    .rts-btn.btn-primary{
        border: 7px solid #fff;
        position: absolute;
        bottom: -65px;
        padding: 42px 64px;
        font-weight: 500;
        min-width: max-content;
        font-size: 30px;
        @media #{$smlg-device} {
            border: 7px solid #fff;
            position: absolute;
            bottom: -40px;
            padding: 27px 20px;
            font-weight: 500;
            min-width: max-content;
            font-size: 24px;
        }
    }
}


.rts-about-area-about{
    position: relative;
    z-index: 1;
    .bg-text{
        position: absolute;
        left: 35%;
        bottom: 50px;
        z-index: -1;
        .title{
            font-size: 150px;
            background: linear-gradient(180deg, #0C0A0A 0%, rgba(12, 10, 10, 0) 100%);
            -webkit-background-clip: text;
            opacity: 0.05;
            text-fill-color: transparent;
            text-transform: uppercase;
            -webkit-text-stroke: 1px black;
            -webkit-text-fill-color: rgb(184, 184, 184);
            min-width: max-content;
            @media #{$smlg-device} {
                font-size: 120px;
            }
            @media #{$md-layout} {
                font-size: 80px;
            }
        }
    }
    &.sustain{
        padding-bottom: 45px;
        .container{
            .about-inner-wrapper-inner{
                .title-three-left{
                    .title{
                        margin-top: 0;
                        line-height: 1;
                    }
                }
            }
            .sustain-inner{
                margin-top: 45px;
                .single-fun-facts-one{
                    background: none;
                    padding: 0 30px 0 0;
                    margin-bottom: 30px;
                    .inner{
                        .icon{
                            padding: 0;
                            margin-top: 10px;
                        }
                        .title{
                            color: #000000;
                        }
                        p{
                            color: var(--color-body);
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }
                .rts-single-service-three{
                    align-items: start;
                    .thumbnail-area{
                        flex-basis:auto;
                        padding: 10px;
                        display: block;
                        width: 130px;
                        border: 1px solid #E1E1E1;
                        img{
                            min-width: unset;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.career{
        .container{
            .about-inner-wrapper-inner{
                margin-bottom: 40px;
                .title-three-left{
                    .title{
                        margin-top: 0;
                        line-height: 1;
                    }
                }
            }
        }
        .sustain-inner{
            background: url(../images/service/25.jpg);
            padding: 250px 0;
            background-size: cover;
            background-position: center;
            background-attachment: fixed;
        }
    }
    &.vision{
        background: #F2F3F5;
        @media(max-width:767px){
            padding: 60px 0 120px 0;
        }
        .container{
            .vision-wrapper{
                position: relative;
                .swiper{
                    overflow: hidden;
                    .swiper-wrapper{
                        position: relative;
                    }
                }
                .vision-main-wrapper{
                    .about-a-p-cont{
                        @media(max-width:991px) and (min-width:767px){
                            margin-top: 0;
                            padding-right: 0;
                            padding-left: 20px;
                        }
                        @media(max-width:767px){
                            margin-top: 30px;
                            padding-left: 10px;
                            text-align: center;
                        }
                    }
                    .thumbnail-area-about{
                        .main{
                            width: 100%;
                        }
                    }
                    .about-inner-wrapper-inner{
                        .title-three-left .title{
                            font-size: 42px;
                            line-height: 52px;
                            @media(max-width:991px) and (min-width:767px){
                                font-size: 34px;
                                line-height: 44px;
                                margin-top: 0;
                                br{
                                    display: none;
                                }
                            }
                        }
                        .main-content-area-about-p{
                            p.disc{
                                padding-right: 30px;
                                margin-bottom: 0;
                                @media(max-width:991px) and (min-width:767px){
                                    margin-top: 20px;
                                    br{
                                        display: none;
                                    }
                                }
                                @media(max-width:767px){
                                    padding-right: 0;
                                    br{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            
        }
        .swiper-pagination{
            position: absolute;
            right: 4%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            display: grid;
            background: #fff;
            padding: 15px 15px 15px 15px;
            border-radius: 20px;
            @media(max-width:767px){
                top: unset;
                bottom: 50px;
                right: 50%;
                transform: translateX(50%);
                display: flex;
                align-items: center;
            }
            .swiper-pagination-bullet{
                width: 8px;
                height: 8px;
                margin-bottom: 5px;
                @media(max-width:767px){
                    margin-bottom: 0;
                    margin: 0 10px;
                }
                &.swiper-pagination-bullet-active{
                    background: var(--color-primary);
                }
            }
        }
    }
    &.safety{
        .thumbnail-area-about{
            .small{
                @media(max-width:767px){
                    right: 0;
                }
            }
        }
        .title-three-left span.pre-title{
            color: var(--color-primary);
        }
        .main-content-area-about-p{
            p.disc{
                &:first-child{
                    margin-bottom: 0;
                }
                &:last-child{
                    margin-top: 20px;
                }
                @media(max-width:500px){
                    br{
                        display: none;
                    }
                }
            }
        }
    }
}
.service-abot-area{
    background: #f5f5f5;
    &.sustain{
        .plr-sm{
            @media(max-width:991px){
                padding: 0 10px;
            }
        }
        .one{
            margin-bottom: 50px;
          .thumbnail-service-about{
            margin-right: 120px;
            @media(max-width:991px){
                margin-right: 0;
                margin-bottom: 30px;
            }
          }
          .service-about-wrapper{
            .title-three-left{
                .title{
                    color: #000000;
                }
            }
          }
        }
        .two{
          .thumbnail-service-about{
            margin-left: 120px;
            @media(max-width:991px){
                margin-left: 0;
                margin-top: 30px;
            }
          }
          .service-about-wrapper{
            .title-three-left{
                .title{
                    color: #000000;
                }
            }
          }
        }
        &.safety{
            background: none;

        }
    }
}


.p-md-0{
    @media(max-width:991px){
        padding-left: 10px !important;
    }
}

.company-solution-four{
    .head{
        h6{
            padding: 12px 20px;
            background: var(--color-primary);
            color: #fff;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            font-family: var(--font-primary);
            margin-bottom: 0;
        }
    }
    .body{
        border: 1px solid #E4E4E4;
        padding: 40px;
        @media #{$large-mobile} {
            padding: 15px;
        }
    }
    .single-item{
        display: flex;
        align-items: center;
        gap: 20px;
        .icon{
            img{
                width: max-content;
            }
            box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.04);
            border-radius: 50%;
        }
        .info{
            .title{
                margin-bottom: 5px;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}

.about-four-img{
    position: relative;
    .thumbnail-1{
        position: relative;
        max-width: max-content;
        z-index: 1;
        &::after{
            position: absolute;
            content: '';
            border: 1px solid #FF6600;
            height: 100%;
            width: 100%;
            left: 30px;
            top: 30px;
            z-index: -1;
        }
        .experience{
            position: absolute;
            right: -220px;
            top: 0;
            display: flex;
            align-items: center;
            gap: 15px;
            @media #{$large-mobile} {
                right: -106px;
            }
            @media #{$small-mobile} {
                right: 15px;
                top: 45px;
            }
            h3{
                font-size: 86px;
                color: var(--color-primary);
            }
            .small-st{
                display: flex;
                flex-direction: column;
                span.plus{
                    color: var(--color-primary);
                    font-size: 20px;
                    font-weight: 600;
                }
                span{
                    color: #000000;
                    font-weight: 600;
                }
            }
        }

    }
    .thumbnail-2{
        position: absolute;
        top: 80px;
        right: 0;
        z-index: 2;
        @media #{$small-mobile} {
            top: 306px;
        }
        span{
            position: absolute;
            min-width: max-content;
            right: 10px;
            top: 10px;
            padding: 8px 30px;
            background: var(--color-primary);
            color: #fff;
            animation: jump-2 5s linear infinite;
            letter-spacing: 0.1em;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
        img{
            border: 10px solid #fff;
            animation: jump-2 5s linear infinite;
            @media #{$small-mobile} {
                width: 60%;
            }
        }
    }
}
.why-choose-padding-con{
    padding-bottom: 116px;
    @media #{$smlg-device} {
        padding-bottom: 0;
    }
}


.rts-why-chooseus-4{
    .title-mid-wrapper-home-two{
        span{
            color: var(--color-primary);
        }
        .title{
            color: #ffff !important;
        }
    }
}
.img-large-h4-ab{
    img{
        margin-top: -120px;
        @media #{$smlg-device} {
            margin-top: 0;
            padding-top: 120px;
        }
    }
}

.inner-content-ab-com{
    p.disc{
        margin-bottom: 30px;
        width: 82%;
        @media #{$sm-layout} {
            width: 100%;
        }
    }
    .btn-group-area{
        display: flex;
        align-items: center;
        gap: 30px;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .rts-btn.btn-primary{
            i{
                margin-left: 15px;
            }
        }
        .user{
            display: flex;
            align-items: center;
            gap: 20px;
            .info{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                .title{
                    margin-bottom: 0px;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    min-width: max-content;
                }
            }
        }
    }
}

// about six home
.home-six{
    span.pre,
    .title-mid-wrapper-home-two span.pre{
        text-transform: uppercase;
        color: var(--color-primary);
        font-family: var(--font-primary);
    }
}

.thumbnail-about-six{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 120px;
    .image-sm{
        position: absolute;
        bottom: -120px;
        left: 0;
        animation: jump-2 5s linear infinite;
        @media #{$small-mobile} {
            width: 60%;
        }
    }
}

.about-six-inner-wrapper{
    p.disc{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #6E777D;
        margin-bottom: 28px;
    }
}
.bg-dark-3{
    background: #171717;
    background-image: url(../images/bg/bg-08.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}


.about-eight-content-main-wrapper{
    .title-area{
        span{
            color: var(--color-primary);
            text-transform: uppercase;
            font-family: var(--font-primary);
        }
        h2.title{
            font-weight: 700;
            font-size: 48px;
            line-height: 73px;
            color: #0C0A0A;
            @media #{$sm-layout} {
                font-size: 36px;
                line-height: 42px;
            }
        }
    }
    p.disc-1{
        margin-bottom: 30px;
    }
    .check-area-inner{
        margin-bottom: 30px;
        .single-check{
            display: flex;
            align-items: center;
            margin: 5px 0;
            @media #{$large-mobile} {
                align-items: flex-start;
            }
            i{
                margin-right: 8px;
                color: var(--color-primary);
                @media #{$large-mobile} {
                    margin-top: 6px;
                }
            }
        }
    }
    .mission-area{
        display: flex;
        align-items: center;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }
        img{
            margin-right: 20px;
        }
        .title{
            margin-bottom: 5px;
        }
        p.disc{
            width: 80%;
        }
    }
    .contacta-area{
        display: flex;
        align-items: center;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;
        }
        .call-area{
            display: flex;
            align-items: center;
            margin-left: 30px;
            @media #{$large-mobile} {
                margin-left: 0;
            }
            .icon{
                margin-right: 13px;
                i{
                    width: 52px;
                    height: 52px;
                    background: var(--color-primary);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotate(-35deg);
                    color: #fff;
                    font-weight: 600;
                }
            }
            .detail{
                span{
                    display: block;
                    text-transform: capitalize;
                }
                a.number{
                    font-weight: 700;
                    font-size: 24px;
                    color: #0C0A0A;
                    line-height: 37px;
                    transition: 0.3s;
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}


.thumbnail-about-eight{
    position: relative;
    .experience-area{
        position: absolute;
        top: 42%;
        right: 40px;
        animation: jump-2 8s linear infinite;
        background: #fff;
        padding:  30px 40px;
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.07);
        @media #{$large-mobile} {
            padding: 6px 17px;
        }
        .image-area{
            position: relative;
            span{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-weight: 600;
                font-size: 40px;
            }
        }
        p{
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: #0C0A0A;
            margin-top: 12px;
        }
    }
}


.about-eight-content-main-wrapper .check-area-inner .single-check p {
    @media #{$smlg-device} {
        font-size: 13px;
    }
}

.about-eight-content-main-wrapper .mission-area p.disc {
    @media #{$smlg-device} {
        width: 100%;
        font-size: 14px;
    }
}


.thumbnail-about-nine{
    margin-left: -100px;
    @media #{$md-layout} {
        margin-left: 0;
    }
    @media #{$sm-layout} {
        margin-left: 0;
    }
    img{
        width: 100%;
    }
}
.about-inner-left-nine{
    padding-left: 40px;
    @media #{$md-layout} {
        margin-top: 30px;
    }
    @media #{$sm-layout} {
        margin-top: 40px;
        padding-left: 0;
    }
}

.title-left-wrapper-home-nine{
    .pre{
        text-transform: uppercase;
        color: var(--color-primary);
        
    }
}

.bg-testi-ten{
    background-image: url(../images/testimonials/06.jpg);
    background-repeat: no-repeat;
    // box-shadow: 0px 14px 70px rgba(0, 0, 0, 0.07);
}


.bg-about-ten{
    background: #F8F8F8;
}

.thumbnail-main-img-company-about{
    position: relative;
    .single-right-content{
        position: absolute;
        right: 0;
        top: 9%;
        background: #fff;
        padding: 35px 40px;
        border: 1px solid #DBDBDB;
        @media #{$sm-layout} {
            padding:17px;
        }
        span{
            color: var(--color-primary);
        }
        h2{
            margin-bottom: 0;
        }
        &.second{
            top: 38%;
        }
        &.last{
            top: 67%;
        }
    }
}