//  rts pagination area start one

.rts-elevate-pagination{
    ul{
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        list-style: none;
        li{
            margin: 0;
            margin-right: 10px;
            button{
                width: 50px;
                height: 50px;
                background: transparent;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000;
                border: 1px solid #E9E9E9;
                transition: .3s;
                &.active{
                    background: var(--color-primary);
                    color: #fff;
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                }
            }
        }
    }
}