// blog style hear

.single-blog-area-one{
    .thumbnail{
        display: block;
        overflow: hidden;
        position: relative;
        &::after{
            position: absolute;
            left: 0;
            top: 0;
            content: 'BUILDING';
            padding: 11px 17px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            line-height: 15px;
            background: var(--color-primary);
            left:25px;
            top: 25px;
            color: #fff;
            letter-spacing: 0.05em;
        }
        img{
            transition: 1.3s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.15);
            }
        }
    }
    .inner{
        padding: 31px 35px 40px;
        background: #fff;
        @media #{$smlg-device} {
            padding: 25px;
        }
        .blog-top{
            display: flex;
            align-items: center;
            margin-bottom: 17px;
            span{
                margin-right: 18px;
                padding-right: 18px;
                position: relative;
                font-family: var(--font-primary);
                font-weight: 600;
                
                @media #{$smlg-device} {
                    min-width: max-content;
                    font-size: 14px;
                }
                &:first-child{
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        background: #D6D6D6;
                    }
                }
            }
        }
        a.title{
            .title{
                transition: .3s;
                line-height: 34px;
                padding-bottom: 25px;
                border-bottom: 1px solid #E8E8E8;
                margin-bottom: 35px;
                @media #{$smlg-device} {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
}

// blog two
.blog-single-two-wrapper{
    border: 1px solid #E9E9E9;
    .image-area{
        position: relative;
        .date-area{
            position: absolute;
            width: 70px;
            height: 78px;
            right: 0;
            bottom: -25.5px;
            text-align: center;
            .date{
                background: var(--color-primary);
                color: #fff;
                padding: 7px 10px;
                span.day{
                    font-size: 26px;
                }
                span.month{
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 12px;
                }
                span{
                    display: block;
                }
            }
            .year{
                background: #0C0A0A;
                span{
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 16px;
                    color: #fff;
                }
            }
        }
        .thumbnail{
            overflow: hidden;
            display: block;
            img{
                width: 100%;
                transition: 1.3s;
            }
            &:hover{
                img{
                    transform: scale(1.15);
                }
            }
        }
    }
    .inner{
        padding: 40px;
        margin-top: -9px;
        @media #{$sm-layout} {
            padding: 20px 10px;
        }
        .title{
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            margin-top: 5px;
            transition: .3s;
            @media #{$sm-layout} {
                font-size: 22px;
                line-height: 28px;
            }
            &:hover{
                color: var(--color-primary);
            }
        }
        .pre-tag{
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            color: #6E777D;
            margin-top: -5px;
        }
        a.btn-read-more-blog{
            color: var(--color-primary);
            align-items: center;
            i{
                margin-left: 5px;
                position: relative;
            }
        }
    }
}

.pd-control-bg--40{
    padding-left: 40px;
    @media #{$smlg-device} {
        margin-top: 20px;
        padding-left: 15px;
    }
}

.bg-journey{
    background: #171717;
    .thumbnail-area{
        position: relative;
        @media(max-width:991px){
            margin-bottom: 100px;
        }
        .reveal-item{
            img{
                width: 100%;
            }
        }
        .happy-clients-area-start{
            padding: 70px 40px 40px;
            background: #FF6600;
            max-width: max-content;
            position: absolute;
            margin-top: -100px;
            margin-left: -100px;
            @media(max-width:1500px){
                margin-left: 0;
            }
            &::after{
                position: absolute;
                content: "";
                display: block;
                position: relative;
                z-index: 3;
                width: 0;
                height: 0;
                border-left: 36px solid var(--color-primary);
                border-bottom: 25px solid transparent;
                top: -83px;
                transform: translate(-50%, -50%);
                left: 135%;
            }
            .img-area-person{
                display: flex;
                align-items: center;
                position: relative;
                img{
                    position: absolute;
                    margin-top: -140px;
                    left: 0;
                    z-index: 3;
                    border: 5px solid #fff;
                    border-radius: 50%;
                    transition: .3s;
                    cursor: pointer;
                    &:nth-child(2){
                        margin-left: 40px;
                        z-index: 2;
                    }
                    &:nth-child(3){
                        margin-left: 80px;
                        z-index: 1;
                    }
                    &:hover{
                        z-index: 5;
                    }
                }
            }
            p{
                color: #fff;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                font-family: var(--font-primary);
            }
        }
    }
    .pl--75{
        @media(max-width: 991px){
            padding-left: 10px !important;
        }
    }
}

.title-mid-wrapper-home-two{
    &.left{
        text-align: left;
        span,
        .title{
            color: #0C0A0A;
            font-family: var(--font-primary);
        }
        .title{
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            margin-top: 10px;
            margin-bottom: 30px;
            @media (max-width:500px){
                font-size: 40px;
                line-height: 50px;
            }
            @media (max-width:400px){
                font-size: 32px;
                line-height: 42px;
            }
            @media(max-width:768px){
                br{
                    display: none;
                }
            }
        }
    }
    .inner-content{
        a.rts-btn.btn-primary{
            background: #FFFFFF !important;
        }
    }
}

.rts-journey-area{

    .title-mid-wrapper-home-two{
        &.left{
            text-align: left;
            span,
            .title{
                color: #fff;
                font-family: var(--font-primary);
            }
            .title{
                font-weight: 600;
                font-size: 48px;
                line-height: 58px;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
        .inner-content{
            a.rts-btn.btn-primary{
                background: #FFFFFF !important;
            }
        }
    }
}

.journey-main-wrapper{
    .inner-content{
        p.disc{
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #6E777D;
            width: 80%;
        }
        a.rts-btn{
            background: #FFFFFF;
            color: var(--color-primary);
            &:hover{
                background: var(--color-primary);
                color: #fff;
            }
        }
    }
}

.rts-single-wized{
    background: transparent;
    border-radius: 0;
    padding: 40px;
    margin-bottom: 40px;
    border: 1px solid #E9E9E9;
    &:last-child{
        margin-bottom: 0;
    }
    @media #{$small-mobile} {
        padding: 20px;
    }
    &.service{
        border-radius: 0;
        .single-categories{
            li{
                a{
                    border-radius: 0;
                }
            }
        }
    }
    &.download{
        background: #171717;
        .title{
            color: #fff;
        }
        .single-download-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #2D2D2D;
            &:first-child{ 
                padding-top: 0;
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
            }
            .mid{
                margin-right: auto;
                margin-left: 15px;
                .title{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-family: var(--font-primary);
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                }
            }
            a{
                &.rts-btn{
                    padding: 11px 15px;
                    border-radius: 0;
                }
            }
        }
    }
    &.contact{
        background: #1C2539;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        @media #{$small-mobile} {
            padding: 25px 20px;
        }
        &:last-child{
            @media #{$small-mobile} {
                margin-bottom: 0;
            }
        }
        .wized-body{
            text-align: center;
            .title{
                color: #fff;
                margin-bottom: 30px;
                font-size: 22px;
                line-height: 32px;
            }
            a{
                &.rts-btn{
                    display: block;
                    max-width: max-content;
                    margin: auto;
                }
            }
        }
    }
    .wized-header{
        .title{
            margin-bottom: 10px;
        }
    }
    .wized-body{
        margin-top: 30px;
        .rts-search-wrapper{
            position: relative;
            input{
                background: transparent;
                height: 55px;
                border-radius: 0;
                padding-right: 70px;
                padding-left: 25px;
                border: 1px solid #E9E9E9;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                position: absolute;
                max-width: max-content;
                height: 55px;
                width: 55px;
                border-radius: 0;
                background: var(--color-primary);
                display: inline-block;
                padding: 0 19px;
                right: 0;
                i{
                    color: #fff;
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }
    }
    .single-categories{
        margin-bottom: 15px;
        padding: 0;
        &:last-child{
            margin-bottom: 0;
        }
        li{
            list-style: none;
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 25px;
                background: transparent;
                color: #5D666F;
                font-weight: 500;     
                transition: .3s;  
                border-radius: 0;
                border: 1px solid #E9E9E9;      
                i{
                    color: var(--color-primary);
                    transition: .3s;
                }
                &:hover{
                    background: var(--color-primary);
                    transform: translateY(-5px) scale(1.03);
                    color: var(--color-white);  
                    i{
                        color: #fff;
                    }  
                }
            }
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 0;
            img{
                min-width: 85px;
                height: auto;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    // gallery post
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 0;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 97px;
                    height: auto;
                    transition: .6s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: 269px;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 16px;
            background: transparent;
            border-radius: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .5s;
            border: 1px solid #E9E9E9;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
                border: 1px solid transparent;
            }
        }
    }
}

.blog-single-post-listing{
    border: 1px solid #E6E9F0;
    margin-bottom: 50px;
    @media #{$smlg-device} {
        margin-right: 0;
    }
    // details style hear...
    &.details{
        border-radius: 0;
        .thumbnail{
            border-radius: 0;
            &.details{
                border-radius: 0;
                width: 100%;
                max-width: max-content;
                @media #{$smlg-device} {
                    max-width: 100%;
                }
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 20px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 0;
                }
            }
        }
        .rts-quote-area{
            padding: 50px;
            background: #F6F6F6;
            border-radius: 0;
            margin-bottom: 40px;
            @media #{$md-layout} {
                padding: 30px;
            }
            @media #{$sm-layout} {
                padding: 10px;
                margin-bottom: 25px;
            }
            @media #{$small-mobile} {
                margin-top: 15px;
            }
            .title{
                margin-bottom: 25px;
                @media #{$small-mobile} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            .name{
                font-size: 18px;
                color: var(--color-primary);
                font-weight: 700;
            }
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #5D666F;
            }
        }
        .check-area-details{
            .single-check{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                i{
                    margin-right: 15px;
                    color: var(--color-primary);
                    @media #{$small-mobile} {
                    margin-top: -26px;
                    }
                }
                span{
                    color: #5D666F;
                }
            }
        }
        .details-tag{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media #{$small-mobile} {
                justify-content: flex-start;
            }
            h6{
                margin-bottom: 0;
                font-size: 18px;
                margin-right: 15px;
            }
            button{
                padding: 8px 12px;
                background: #F6F6F6;
                max-width: max-content;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 0;
                color: #1C2539;
                transition: .3s;
                &:last-child{
                    @media #{$laptop-device} {
                        margin-top: 10px;
                        margin-left: -2px;
                    }
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: translateY(-2px) scale(1.02);
                }
            }
        }
        .details-share{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media #{$md-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$sm-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                justify-content: flex-start;
                margin-top: 20px;
            }
            button{
                max-width: max-content;
                position: relative;
                z-index: 1;
                margin-left: 23px;
                color: #1C2539;
                transition: .3s;
                font-size: 14px;
                &::after{
                    position: absolute;
                    content: '';
                    background: #F6F6F6;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: .3s;
                }
                &:hover{
                    color: #fff;
                    transform: scale(1.2);
                    &::after{
                        background: var(--color-primary);
                    }
                }
            }
            h6{
                font-size: 18px;
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .author-area{
            margin-top: 44px;
            display: flex;
            align-items: center;
            padding: 40px 0;
            border-top: 1px solid #E6E9F0;
            border-bottom: 1px solid #E6E9F0;
            @media #{$sm-layout} {
                align-items: flex-start;
            }
            @media #{$large-mobile} {
                flex-wrap: wrap;
            }
            .thumbnail{
                margin-right: 30px;
                @media #{$sm-layout} {
                    margin-right: 0;
                }
            }
            .author-details{
                @media #{$sm-layout} {
                    margin-left: 15px;
                }
                h5{
                    margin-bottom: 10px;
                }
                p{
                    line-height: 26px;
                }
            }
        }
    }

    .replay-area-details{
        margin-top: 40px;
        form{
            input{
                height: 55px;
                border-radius: 0;
                background: transparent;
                border: 1px solid #E9E9E9;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                border-radius: 0;
                background: transparent;
                height: 140px;
                margin-top: 20px;
                padding: 15px;
                border: 1px solid #E9E9E9;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }


    .thumbnail{
        overflow: hidden;
        img{
            transition: .8s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .blog-listing-content{
        padding: 50px;
        @media #{$sm-layout} {
            padding: 25px 10px;
        }
        .user-info{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            flex-wrap: wrap;
            .single{
                margin-right: 30px;
                min-width: max-content;
                @media #{$sm-layout} {
                    margin-right: 5px;
                }
                @media #{$large-mobile} {
                    margin-right: 5px;
                }
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                    @media #{$large-mobile} {
                        margin-right: 2px;
                        font-size: 14px;
                    }
                }
                span{
                    @media #{$large-mobile} {
                        font-size: 13px;
                    }
                }
            }
        }
        .blog-title{
            transition: .3s;
            .title{
                transition: .3s;
                margin-bottom: 16px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
                // margin-bottom: 32px;
                @media #{$small-mobile} {
                    margin-bottom: 15px;
                }
            }
        }
        a{
            &.rts-btn{
                margin-top: 35px;
                display: block;
                max-width: max-content;
                @media #{$small-mobile} {
                    margin-top: 20px;
                }
            }
        }
    }
}

.single-leaders-area{
    .rts-btn{
        padding: 14px 25px;
    }
}
.pl-b-list-controler{
    padding-left: 40px;
    @media #{$smlg-device} {
        padding-left: 15px;
    }
}

.blog-style-four{
    .thumbnail{
        overflow: hidden;
        display: block;
        img{
            transition: .6s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .inner-content{
        padding: 30px;
        background: #fff;
        @media #{$sm-layout} {
            padding: 15px;
        }
        .head{
            display: flex;
            align-items: center;
            gap: 24px;
            margin-bottom: 13px;
            .icon-area-single{
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                }
            }
        }
        a.main-title .title{
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #EEEE;
            transition: .3s;
            @media #{$sm-layout} {
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
            &:hover{
                color: var(--color-primary);
            }
        }
        .footer-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-area{
                display: flex;
                align-items: center;
                .info{
                    margin-left: 10px;
                    .title{
                        margin-bottom: -2px;
                        font-size: 18px;
                        @media #{$large-mobile} {
                            font-size: 14px;
                        }
                    }
                }
            }
            .rts-btn{
                padding: 15px 18px;
                font-size: 14px;
            }
        }
    }
}

// blog post area start
.single-blog-post-style-six{
    display: flex;
    align-items: center;
    background: #1F1F1F;
    @media #{$sm-layout} {
        flex-direction: column;
    }
    .thumbnail{
        margin-right: 40px;
        display: block;
        overflow: hidden;
        max-width: max-content;
        min-width: max-content;
        @media #{$sm-layout} {
            max-width: inherit;
            min-width: inherit;
            width: 100%;
            margin-right: 0;
        }
        img{
            transition: 1.2s;
            @media #{$sm-layout} {
                width: 100%;
            }
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .inner-info{
        @media #{$sm-layout} {
            padding: 30px;
        }
        @media #{$large-mobile} {
            padding: 15px;
        }
        .head{
            display: flex;
            align-items: center;
            .single{
                display: flex;
                align-items: center;
                margin-right: 25px;
                p{
                    color: #fff;
                }
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                }
            }
        }
        a .title{
            color: #fff;
            margin-top: 20px;
            padding-bottom: 25px;
            border-bottom: 1px solid #171717;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            transition: .3s;
            @media #{$smlg-device} {
                margin-top: 15px;
                padding-bottom: 20px;
                font-size: 18px;
                line-height: 27px;
            }
            @media #{$md-layout} {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
            }
            &:hover{
                color: var(--color-primary);
            }
        }
        .btn-read-more-border{
            border: 1px solid #171717;
            color: #fff;
            margin-top: 25px;
            transition: .3s;
            padding: 15px 23px;
            &:hover{
                background: var(--color-primary);
                border: 1px solid var(--color-primary);
            }
        }
    }
}
.single-blog-six-right{
    height: 100%;
    background: #1F1F1F;
    .thumbnail{
        display: block;
        overflow: hidden;
        img{
            transition: 1.2s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .inner-content-b-6{
        padding: 30px;
        .head{
            display: flex;
            align-items: center;
            .single{
                margin-right: 25px; 
                display: flex;
                align-items: center;
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                }
                p{
                    color: #fff;
                    @media #{$smlg-device} {
                        font-size: 15px;
                    }
                }
            }
        }
        a .title{
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: #FFFFFF;
            margin-top: 13px;
            padding-bottom: 25px;
            transition: .3s;
            border-bottom: 1px solid #171717;
            @media #{$smlg-device} {
                font-size: 18px;
                line-height: 27px;
                color: #FFFFFF;
                margin-top: 8px;
            }
            &:hover{
                color: var(--color-primary);
            }
        }
        .btn-read-more-border{
            border: 1px solid #171717;
            color: #fff;
            margin-top: 25px;
            transition: .3s;
            padding: 15px 23px;
            &:hover{
                background: var(--color-primary);
                border: 1px solid var(--color-primary);
            }
        }
    }
}



















#elevate-load {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #111111;
    z-index: 999999;
  }
  #elevate-load .preloader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    text-align: left;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
  }
  #elevate-load .preloader span {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #111111;
    animation: preloader 1.3s linear infinite;
  }
  #elevate-load .preloader span:last-child {
    animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s;
  }
  .loaded{
    display: none;
  }
  @keyframes preloader {
    0% {
      transform: scale(0, 0);
      opacity: 0.5;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }

.lds-ellipsis {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }



  .single-blog-ten-single{
    .content-wrapper{
        padding: 40px;
        background: #F6F6F6;
        @media #{$sm-layout} {
            padding: 10px;
        }
        .header-area-blg{
            display: flex;
            align-items: center;
            .single{
                margin-right: 40px;
                @media #{$sm-layout} {
                    margin-right: 10px;
                }
            }
        }
        .content-area{
            .title{
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                color: #0C0A0A;
                margin-top: 10px;
                transition: .3s;
                @media #{$sm-layout} {
                    font-size: 16px;
                    line-height: 24px;
                }
                &:hover{
                    color: var(--color-primary);
                }
            }
        }
    }
    .header-area-blg{

        .single{
            display: flex;
            align-items: center;
            margin-right: 30px;
            i{
                margin-right: 10px;
                color: var(--color-primary);
            }
        }
    }
  }